import React, { useContext } from "react"

import "typeface-roboto"

import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"

import Layout from "components/layout"
import SEO from "components/seo"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import NavPills from "components/NavPills/NavPills.js"

import img_yk from "assets/img/yk.jpg"
import GetGroups from "components/Groups/Groups.jsx"

import styles from "assets/jss/material-kit-react.js"

import {
  // GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"

const useStyles = makeStyles(styles)

const IndexPage = () => {
  const classes = useStyles()
  const state = useContext(GlobalStateContext)

  console.log("YK5 state ", state)
  // const groups = state.groups

  const setupNavTabs = () => {
    var tabs = []
    Object.keys(state.years).forEach((year) => {
      if (state.years[year]) {
        tabs.push({
          tabButton: year,
          tabContent: <GetGroups groupType="regular" />,
        })
      } else {
        tabs.push({
          tabButton: year,
          tabContent:
            "* 團期還在準備中，預計出團一年前公布，敬請期待。 需要被通知的請Email到info@ynltour.com *",
        })
      }
    })

    return tabs
  }

  return (
    <Layout>
      <SEO title="5天4夜團" />
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            {/* <div className={classes.profile}> */}
            <div>
              <div>
                <img src={img_yk} alt="..." />
              </div>
              {/* <div className={classes.name}> */}
              <div>
                <h3 className={classes.title}>5天4夜團</h3>
                <h6>黃刀鎮，加拿大</h6>
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <Typography component="div" color="error">
          <Box textAlign="center">(需要資料: 五天四夜團介紹)</Box>
        </Typography>
        {/* <div className={classes.description}></div> */}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <NavPills
              alignCenter
              color="primary"
              tabs={setupNavTabs()}
              // tabs={[
              //   {
              //     tabButton: "2020",
              //     // tabIcon: Camera,
              //     tabContent: <GetGroups groupType="regular" />,
              //     // tabContent: "hello",
              //   },
              //   {
              //     tabButton: "2021",
              //     // tabIcon: Palette,
              //     tabContent: "* 預計出團一年前公布，敬請期待。 *",
              //   },
              // ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  )
}

export default IndexPage
